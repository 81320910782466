<template>
  <div class="w-full trails">
    <h1 class="mb-4 text-2xl uppercase">Capture Your Hiking Memories</h1>
    <div class="buttons" v-if="state.isLoggedIn">
      <base-button class="mb-4" @click="newItem()"> NEW PICTURE </base-button>
    </div>
    <!-- end New Picture button -->
    <base-card v-for="trail of allTrails" :key="trail" class="trail-card">
      <div class="sm:flex">
        <div class="w-full sm:w-1/2 lg:w-1/3">
          <div
            class="relative w-full mb-3 overflow-hidden pb-1/1"
            v-if="trail && trail['imagename']"
          >
            <img
              class="absolute object-cover w-full h-full"
              :src="
                'https://storage.googleapis.com/trail-pictures.appspot.com/user-images/resized/' +
                trail['imagename']
              "
              :alt="trail.content"
            />
          </div>
          <div v-if="trail && trail.content" v-html="trail.content"></div>
        </div>
        <!-- end image -->
        <div
          class="flex items-center justify-center w-full align-middle sm:w-1/2 lg:w-1/3"
        >
          <div v-if="trail && trail['latitude'] && state.isLoggedIn">
            <p>
              Picture Taken: {{ trail["dateTaken"] }} @{{ trail["timeTaken"] }}
            </p>
            <p>
              Latitude: {{ trail["latitude"] }}<br />
              Longitude: {{ trail["longitude"] }}
            </p>
            <p>
              <a
                :href="
                  'https://www.google.com/maps/place/' +
                  trail['latitude'] +
                  '+' +
                  trail['longitude']
                "
                target="_blank"
                >View on Map</a
              >
            </p>
          </div>
        </div>
      </div>
      <div class="buttons" v-if="state.isLoggedIn">
        <base-button @click="editItem(trail)"> EDIT PICTURE </base-button>
      </div>
    </base-card>
  </div>

  <admin-trails
    :trail="trail"
    :toggleModal="toggleModal"
    :refreshPage="refreshPage"
    v-if="trails_show"
  ></admin-trails>
</template>

<script>
import { ref, onBeforeMount, inject } from "vue";
import useStore from "@/store";
import { getAllOfPageType, loginStatus } from "../firebase.js";
import AdminTrails from "@/admin/AdminTrails.vue";

export default {
  setup() {
    const { state } = useStore();
    const page = ref("trails");
    const type = ref("my-trails");
    const blank = {
      id: "0",
      page: page.value,
      type: type.value,
      sortOrder: 0,
      content: "",
    };
    const trail = ref(blank);
    let allTrails = ref([]);
    let isAuthenticated = ref("unauthorized");
    const trails_show = ref(false);
    const selectedItem = ref({});
    onBeforeMount(async () => {
      isAuthenticated.value = await loginStatus();
      refreshPage();
    });
    const toggleModal = (section) => {
      eval(`${section}_show`).value = !eval(`${section}_show`).value;
    };
    const refreshPage = async () => {
      const data = await getAllOfPageType(page.value, type.value);
      if (data.length) {
        allTrails.value = data;
        console.log("allTrails data:", allTrails.value);
      }
    };
    const editItem = (item) => {
      const editorVars = ["content"];
      for (const e of editorVars) {
        if (item && item[e]) {
          item[e] = item[e]
            .replace(/\\n \+\n/g, "")
            .replace(/    /g, "")
            .replace(/\n/g, "");
        }
      }
      selectedItem.value = item;
      trail.value = item;
      toggleModal("trails");
    };
    const newItem = () => {
      const editorVars = ["content"];
      const item = blank;
      selectedItem.value = item;
      trail.value = item;
      toggleModal("trails");
    };
    return {
      state,
      allTrails,
      trail,
      trails_show,
      getAllOfPageType,
      isAuthenticated,
      editItem,
      newItem,
      selectedItem,
      toggleModal,
      AdminTrails,
      refreshPage,
    };
  },
};
</script>

<style lang="scss">
.img-holder {
  max-width: 460px;
}
.header-description p {
  margin-bottom: 1rem;
}
.trails p {
  margin-bottom: 1rem;
}
.trail-card {
  // border: 1px solid #ccc;
  padding: 1rem;
  margin-bottom: 1rem;
}
</style>
