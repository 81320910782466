<template>
  <the-modal>
    <div class="trails-admin" v-if="trail">
      <form class="text-left" @submit.prevent="submitForm">
        <label class="block">
          <span class="text-xs text-gray-700">Content</span>
          <editor
            class="tiny-editor"
            api-key="ryi7etalht0g3h0yl1v8ucpe9304iyxn7irbggeo8txpua5m"
            v-model="trail.content"
            :init="{
              height: '200',
              menubar: false,
              plugins: 'lists link code',
              toolbar:
                'code | bold italic | alignleft aligncenter alignright | bullist numlist | link',
            }"
          />
        </label>
        <input
          type="file"
          @change="onFileSelected"
          name="formImage"
          class="block my-4"
        />
        <button
          class="px-6 py-2 mr-2 text-lg text-gray-200 bg-blue-600 rounded hover:opacity-75"
          type="submit"
        >
          Update
        </button>
        <button
          class="px-6 py-2 mr-2 text-lg text-gray-200 bg-red-600 rounded hover:opacity-75"
          @click.prevent="toggleModal(page)"
        >
          Cancel
        </button>
      </form>
      <img id="image" />
      <the-spinner v-if="loadingState" />
    </div>
  </the-modal>
</template>

<script>
import axios from "axios";
import Editor from "@tinymce/tinymce-vue";
import TheModal from "@/components/TheModal.vue";
import TheSpinner from "@/components/TheSpinner.vue";
import UUID from "uuid-v4";
export default {
  props: ["trail", "toggleModal", "refreshPage"],
  components: { Editor, TheModal, TheSpinner },
  data() {
    return {
      page: "trails",
      type: this.trail.type,
      loadingState: false,
      // appUrl: "http://localhost:5001/trail-pictures/us-central1/app",
      appUrl: "https://us-central1-trail-pictures.cloudfunctions.net/app",
    };
  },
  methods: {
    submitForm() {
      // console.log("trail obj:", this.trail);
      let fd = new FormData();
      for (const [key, value] of Object.entries(this.trail)) {
        fd.append(key, value);
      }
      if (this.trail.selectedFile) {
        this.loadingState = true;
        fd.append(
          "file",
          this.trail.selectedFile,
          this.trail.selectedFile.name
        );
        const uuid = UUID();
        const uniqueName = `img_${uuid}_480x480.jpeg`;
        // fd.append("imagename", this.trail.selectedFile.name);
        fd["imagename"] = uniqueName;
      }
      axios
        .post(`${this.appUrl}/api/update/dynamicFormData`, fd)
        .then((result) => {
          // console.log("result:", result);
          if (this.loadingState) {
            setTimeout(() => {
              this.refreshPage();
              this.toggleModal(this.page);
              this.loadingState = false;
              this.trail = {
                id: "0",
                page: page.value,
                type: type.value,
                sortOrder: 0,
                content: "",
              };
            }, 5000);
          } else {
            this.toggleModal(this.page);
          }
        })
        .catch((err) => {
          console.log("err:", err);
        });
    },
    async onFileSelected(event) {
      this.trail.selectedFile = event.target.files[0];
      this.parseExifData(event.target.files[0], this.setGPS, this.setDateTime);
      const srcEncoded = await this.resizeImage(event.target.files[0]);

      let blobBin = atob(srcEncoded.split(",")[1]);
      let array = [];
      for (let i = 0; i < blobBin.length; i++) {
        array.push(blobBin.charCodeAt(i));
      }
      this.trail.selectedFile = new Blob([new Uint8Array(array)], {
        type: "image/png",
      });
    },
    resizeImage(item) {
      return new Promise(function (resolve, reject) {
        try {
          const rsDimension = 1024;
          const reader = new FileReader();
          reader.readAsDataURL(item);
          reader.name = item.name;
          reader.size = item.size;
          reader.onload = (event) => {
            const img = new Image();
            img.src = event.target.result;
            img.name = event.target.name;
            img.size = event.target.size;
            img.onload = (el) => {
              const elem = document.createElement("canvas");
              let scaleFactor = 1;
              if (el.target.width < el.target.height) {
                scaleFactor = rsDimension / el.target.width;
                elem.height = el.target.height * scaleFactor;
                elem.width = rsDimension;
              } else {
                scaleFactor = rsDimension / el.target.height;
                elem.height = rsDimension;
                elem.width = el.target.width * scaleFactor;
              }
              const ctx = elem.getContext("2d");
              ctx.drawImage(el.target, 0, 0, elem.width, elem.height);
              const srcEncoded = ctx.canvas.toDataURL(
                el.target,
                "image/jpeg",
                0
              );
              resolve(srcEncoded);
            };
          };
        } catch (err) {
          reject(err);
        }
      });
    },
    setGPS(lat, lon) {
      this.trail["latitude"] = lat;
      this.trail["longitude"] = lon;
    },
    setDateTime(dateTime, offsetTime) {
      let date = dateTime.substr(0, 10);
      const dateArr = date.split(":");
      date = `${dateArr[1]}/${dateArr[2]}/${dateArr[0]}`;
      const timeArr = dateTime.substr(11, 8).split(":");
      let hours = +timeArr[0];
      let suffix = "am";
      if (hours > 12) {
        suffix = "pm";
        hours = hours - 12;
      }
      if (timeArr[0] === "12") {
        suffix = "pm";
      }
      if (timeArr[0] === "24") {
        suffix = "am";
      }
      const time = `${hours}:${timeArr[1]} ${suffix}`;
      this.trail["dateTaken"] = date;
      this.trail["timeTaken"] = time;
      this.trail["offsetTime"] = offsetTime;
    },
    parseExifData(file, setGPS, setDateTime) {
      function convertDMSToDD(dd, direction) {
        if (direction == "S" || direction == "W") {
          dd = dd * -1;
        } // Don't do anything for N or E
        return dd;
      }
      var reader = new FileReader();
      reader.onload = function (readerEvent) {
        try {
          var tags = ExifReader.load(readerEvent.target.result);
          delete tags["MakerNote"];
          // If you want to extract the thumbnail
          // if (tags["Thumbnail"] && tags["Thumbnail"].image) {
          //   var image = document.getElementById("thumbnail");
          //   image.classList.remove("hidden");
          //   image.src = "data:image/jpg;base64," + tags["Thumbnail"].base64;
          // }
          if (tags && tags.GPSLatitude) {
            // console.log(tags);
            setDateTime(tags.DateTime.description, tags.OffsetTime.description);
            setGPS(
              convertDMSToDD(
                tags.GPSLatitude.description.toFixed(4),
                tags.GPSLatitudeRef.value[0]
              ),
              convertDMSToDD(
                tags.GPSLongitude.description.toFixed(4),
                tags.GPSLongitudeRef.value[0]
              )
            );
          } else {
            alert(
              "Sorry, your picture does not contain any geolocation information."
            );
          }
        } catch (error) {
          console.error(error);
        }
      };
      reader.readAsArrayBuffer(file);
    },
  },
};
</script>

<style lang="scss">
.trails-admin {
  color: #000;

  .tiny-editor {
    min-height: 400px;
  }
}
</style>