<template>
  <div class="flex items-center justify-center overflow-y-scroll the-modal">
    <div
      class="h-auto p-8 mx-2 text-left bg-gray-200 rounded shadow-xl md:p-6 lg:p-8 md:mx-0"
    >
      <!-- <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left"> -->
      <div class="mt-3 text-center sm:mt-0 sm:text-left">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
.the-modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: calc(100%);
  background-color: rgba(0, 0, 0, 0.5);
}
</style>